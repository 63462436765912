import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import {
  ITreeOptions,
  TreeComponent,
} from '@circlon/angular-tree-component';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DepartmentService } from 'src/app/modules/department/department.service';
import { AlertService } from '../../../core/services/alert.service';
import { FolderService } from '../../../modules/folder/folder.service';
import { FolderDetail } from '../../../modules/folder/shared/folder.models';

@Component({
  selector: 'app-department-tree-folder',
  templateUrl: './department-tree-folder.component.html',
  styleUrls: ['./department-tree-folder.component.scss'],
})
export class DepartmentTreeFolderComponent
  implements OnInit, OnChanges
{
  @ViewChild('treeRoot', { static: false })
  private tree: TreeComponent;

  @Input() multiple = true;
  @Input() model: { id: number; name: string }[] | number[] = [];

  @Input() placeholder = '';
  @Input() form: FormControl;
  @Input() defaultValue;
  @Input() disable = false;
  @Input() parentId: number;
  @Input() isCreate = false;

  @Input() isAllDepartment = false;

  @Output() modelChange = new EventEmitter<
    number[] | number | string
  >();

  isShow = false;
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };
  defaultDepartment = [];
  nodes;
  items = [];
  loading = false;
  formDirective: FormGroupDirective;
  allNodesLength = 0;

  ALL_DATA = {
    display_name: 'แผนกทั้งหมด',
    display_name_en: 'All departments',
    id: 'all',
    department_name: 'แผนกทั้งหมด',
    department_name_en: 'All departments',
    children: [],
  };

  checkAllDepartment: boolean;

  constructor(
    private departmentService: DepartmentService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private alert: AlertService,
    private folderService: FolderService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.defaultDepartment.length) {
      if (this.parentId && this.parentId > 0) {
        this.folderService
          .getFolder(this.parentId)
          .subscribe((res: FolderDetail) => {
            this.defaultDepartment = res.departments;
            if (this.isCreate) {
              this.defaultDepartment = this.defaultDepartment.concat(
                res.additional_departments,
              );
              this.model = this.defaultDepartment;
              this.mapNameFromModel();
            }
          });
      }
    }

    if (changes.model) {
      this.model = changes.model.currentValue;
      if (this.model.length > 0) {
        this.model.forEach((element) => {
          this.setNode(element.id ? element.id : element);
        });
      } else {
        this.items = this.model;
      }
    }
  }

  ngOnInit(): void {
    const params = {
      page_size: 1000,
    };
    this.departmentService
      .getDepartmentTree(params)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((res) => {
        this.nodes = _.cloneDeep(res);
        this.getNodeNumber();
        this.nodes.unshift(this.ALL_DATA);
        this.loading = true;
        if (this.model.length) {
          this.mapNameFromModel();
        } else if (this.form && this.form.value?.length) {
          this.model = this.form.value;
          this.mapNameFromModel();
        } else if (this.defaultValue) {
          this.items = this.defaultValue;
        }
      });
  }

  getNodeNumber(): void {
    this.nodes
      .filter((obj) => obj.id !== this.ALL_DATA.id)
      .forEach((obj) => {
        this.allNodesLength += obj.descendants_ids.length + 1;
      });
  }

  mapNameFromModel(): void {
    const params = {
      id_list: this.isAllDepartment ? '' : this.model.join('|'),
      page_size: 1000,
    };
    this.departmentService
      .getDepartmentList(params)
      .subscribe((res: any) => {
        this.items = res.results;
        if (this.isAllDepartment) {
          this.handleSelectAll(true, true);
          this.emitData();
        }
      });
  }

  nodeChecked(node, check, children = true): void {
    if (this.defaultDepartment.includes(node.data.id)) {
      this.alert.error(
        this.translate.instant('FOLDER.CANNOT-DELETE'),
      );
      return;
    }
    if (node.data.id === this.ALL_DATA.id) {
      this.handleSelectAll(check, children);
    } else {
      this._nodeChecked(node, check, children);
    }
    this.emitData();
  }

  handleSelectAll(check: boolean, children = true): void {
    if (check) {
      this.checkAllDepartment = true;
      this.tree.treeModel.doForAll((obj) =>
        this._nodeChecked(obj, check, children),
      );
    } else {
      this.checkAllDepartment = false;
      this.tree.treeModel.selectedLeafNodeIds = Object.assign({});
      this.clearValue();
      // set to default
      if (this.defaultDepartment.length) {
        this.model = this.defaultDepartment;
        this.mapNameFromModel();
      }
    }
  }

  closeAllDepartment(): void {
    this.handleSelectAll(false, false);
    const value = [];
    this.modelChange.emit(value);
    if (this.form) {
      this.form.setValue(value);
    }
  }

  emitData(): void {
    const value = this.multiple
      ? this.items.map((item) => item.id)
      : this.items.map((item) => item.id).join('');
    this.modelChange.emit(value);
    if (this.form) {
      this.form.setValue(value);
    }
  }

  _nodeChecked(node, check, children): void {
    if (check) {
      if (!this.tree.treeModel.isSelected(node)) {
        this.tree.treeModel.setSelectedNode(node, true);
        this.expandAllChain(node);
      }

      if (
        node.data.id !== this.ALL_DATA.id &&
        this.items.find((obj) => obj.id === node.data.id) ===
          undefined
      ) {
        this.items.push(node.data);
      }

      if (children) {
        node.children
          .filter((obj) => obj !== undefined)
          .forEach((element) => {
            this._nodeChecked(element, true, true);
          });
      }
      this.checkIfSelectedAll();
    } else {
      this.items = this.items.filter(
        (obj) => obj.id !== node.data.id,
      );
      if (children) {
        node.children.forEach((element) => {
          this.tree.treeModel.setSelectedNode(element, false);
        });
      }
      this.checkAllDepartment = false;
      // deselect all
      this.setNode(this.ALL_DATA.id, false);
    }
  }

  checkIfSelectedAll(): void {
    if (this.items.length === this.allNodesLength) {
      this.checkAllDepartment = true;
      this.setNode(this.ALL_DATA.id);
    }
  }

  onActivate(event): void {
    this.tree.treeModel.setExpandedNode(event.node, true);
  }

  deleteItem(index: number): void {
    if (this.defaultDepartment.includes(this.items[index].id)) {
      this.alert.error(
        this.translate.instant('FOLDER.CANNOT-DELETE'),
      );
      return;
    }
    this.items.splice(index, 1);
    const value = this.multiple
      ? this.items.map((item) => item.id)
      : this.items.map((item) => item.id).join('');
    this.modelChange.emit(value);
    if (this.form) {
      this.form.setValue(value);
    }
  }

  showDropdown(): void {
    this.isShow = !this.isShow;
    this.cd.detectChanges();
    if (this.isShow && this.tree) {
      this.checkIfSelectedAll();
      if (typeof this.items === 'object') {
        this.items.forEach((element) => {
          this.setNode(element.id ? element.id : element);
        });
      }
    }
  }

  setNode(id: number | string, value = true): void {
    if (this.tree && this.tree.treeModel) {
      const node = this.tree.treeModel.getNodeById(id);
      this.expandAllChain(node);
      this.tree.treeModel.selectedLeafNodeIds = Object.assign(
        {},
        this.tree.treeModel.selectedLeafNodeIds,
        { [node.id]: value },
      );
    }
  }

  expandAllChain(node) {
    while (node.parent !== null) {
      this.tree.treeModel.setExpandedNode(node.parent, true);
      node = node.parent;
    }
  }

  clearValue(): void {
    this.items = [];
    this.modelChange.emit('');
    if (this.form && this.form.value) {
      this.form.setValue('');
    }
  }
}
