import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ThemeList, ThemeService } from '../../service/theme.service';

@Component({
  selector: 'app-delete-folder-confirmation-modal',
  templateUrl: './delete-folder-confirmation-modal.component.html',
  styleUrls: ['./delete-folder-confirmation-modal.component.scss'],
})
export class DeleteFolderConfirmationModalComponent {
  modal: NgbModalRef;
  @Input() data: any;
  @Output() submitted = new EventEmitter();
  themeList: ThemeList;

  deleteMemo = false;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    private themeService: ThemeService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  open(content) {
    this.modal = this.modalService.open(content, { centered: true });
  }

  checkDeleteMemo() {
    this.deleteMemo = !this.deleteMemo;
  }

  submit() {
    this.data.deleteMemo = this.deleteMemo;
    this.submitted.emit({ data: this.data });
    this.modal.close();
  }
}
