<div *ngIf="clickable" >
  <div *ngIf="folderList.length === 0 || existAllEntity"
       class="detail-content"
       [routerLink]="['/', 'folder', 'files']">
    <span *ngIf="numberCount">{{'1. '}}</span>
    <span *ngIf="!numberCount">{{ '- ' }}</span>
    <span class="pointer sub-folder">
      {{ 'FOLDER.ALL-ENTITY' | translate}}
    </span>
  </div>
  <div *ngIf="folderList" class="detail-content">
    <div class="mt-2" *ngFor="let folders of folderList; let i = index;">
      <span *ngIf="numberCount && !(folderList.length === 0 || existAllEntity)">{{ i + 1 }}{{'. '}}</span>
      <span *ngIf="numberCount && (folderList.length === 0 || existAllEntity)">{{ i + 2 }}{{'. '}}</span>
      <span *ngIf="!numberCount">{{ '- ' }}</span>
      <div class="d-inline" *ngFor="let folder of folders; let j = index;">
        <div class="sub-folder d-inline-block mb-1 pointer" (click)="navigatePath(folders, j)">
          <span >{{ folder.name }}</span>
        </div>
        <span class="ml-1 mr-1" *ngIf="j+1 !== folders.length">{{ '>' }}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!clickable" >
  <div *ngIf="!folderList || existAllEntity"
       class="detail-content">
    <span *ngIf="numberCount">{{'1. '}}</span>
    <span *ngIf="!numberCount">{{ '- ' }}</span>
    <span class="sub-folder">
      {{ 'FOLDER.ALL-ENTITY' | translate }}
    </span>
  </div>
  <div *ngIf="folderList" class="detail-content">
    <div class="mt-2" *ngFor="let folders of folderList; let i = index;">
      <span *ngIf="numberCount && !(!folderList || existAllEntity)">{{ i + 1 }}{{'. '}}</span>
      <span *ngIf="numberCount && (!folderList || existAllEntity)">{{ i + 2 }}{{'. '}}</span>
      <span *ngIf="!numberCount">{{ '- ' }}</span>
      <span *ngFor="let folder of folders; let j = index;">
        <span class="sub-folder mb-1">{{ folder.name }}</span>
        <span *ngIf="j+1 !== folders.length">{{ ' > ' }}</span>
      </span>
    </div>
  </div>
</div>

