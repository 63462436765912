import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { FolderService } from '../../../modules/folder/folder.service';
import { SpinnerService } from '../../../core/services/spinner.service';

export type ActionType = 'approve' | 'reject' | 'terminate';

@Component({
  selector: 'app-folder-tree-display',
  templateUrl: './folder-tree-display.component.html',
  styleUrls: ['./folder-tree-display.component.scss'],
})
export class FolderTreeDisplayComponent implements OnInit {
  keepAllParams;
  folderList = [];
  nodes;

  @Input() entityIdList: any;
  @Input() clickable = true;
  @Input() numberCount = true;
  @Input() existAllEntity = false;

  constructor(
    private folderService: FolderService,
    private router: Router,
    private spinner: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    if (this.entityIdList.length > 0) {
      const params = {
        id_list: [...new Set(this.entityIdList)].join('|'),
        is_all: false,
        page_size: 1000,
      };
      this.folderService.getFolderTree(params).subscribe((res) => {
        this.nodes = _.cloneDeep(res);

        this.nodes.forEach((folder) => {
          const expandFolder = [];
          do {
            expandFolder.push(folder);
            folder = folder.children[0];
          } while (folder);
          this.folderList.push(expandFolder);
        });
        this.spinner.hide();
      });
    } else {
      this.nodes = [];
      this.spinner.hide();
    }
  }

  navigatePath(folders, j) {
    const folder = folders[j];
    const parentGroupNext = 'parentGroup' + (folder.level + 1);
    const newAllParams = {};
    const convertParams = {};
    convertParams[parentGroupNext] = {
      parentList: folders.slice(0, j + 1),
      page_size: 1000,
      page: 1,
      search: '',
    };
    newAllParams[parentGroupNext] = _.cloneDeep(
      convertParams[parentGroupNext],
    );
    this.keepAllParams = newAllParams;
    this.router.navigate(['folder', 'files'], {
      queryParams: {
        returnQuery: btoa(
          unescape(
            encodeURIComponent(
              JSON.stringify({
                ...this.keepAllParams,
                parent_level: folder.level + 1,
                current_parent: folder,
              }),
            ),
          ),
        ),
      },
    });
  }
}
