import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';

@Injectable({
  providedIn: 'root',
})
export class FolderService {
  static folderTreeResponse = null;

  constructor(private http: ApiService) {}

  static clearFolderTreeCache() {
    FolderService.folderTreeResponse = null;
  }

  getFolderList(data?) {
    return this.http.get(API_URL.folders, data);
  }

  getFileList(data?) {
    return this.http.get(API_URL.files, data);
  }

  getfileTaskList(data?) {
    return this.http.get(API_URL.file_tasks, data);
  }

  addFolder(data) {
    return this.http.post(API_URL.folders, data);
  }

  addStampFile(id, data) {
    return this.http.patch(API_URL.folders + id + '/', data);
  }

  addFileTask(data) {
    return this.http.post(API_URL.file_tasks, data);
  }

  addUploadFileToTask(id, data) {
    return this.http.patch(API_URL.file_tasks + id + '/', data);
  }

  deleteFolder(id, data?) {
    return this.http.post(
      API_URL.folders + id + '/delete-folder/',
      data,
    );
  }

  editFolder(id, data) {
    return this.http.patch(API_URL.folders + id + '/', data);
  }

  getFolder(id) {
    return this.http.get(API_URL.folders + id + '/');
  }

  getFileTask(id) {
    return this.http.get(API_URL.file_tasks + id + '/');
  }

  approveFileTask(id, data?) {
    return this.http.post(
      API_URL.file_tasks + id + '/approve/',
      data,
    );
  }

  terminateFileTask(id, data?) {
    return this.http.post(
      API_URL.file_tasks + id + '/terminate/',
      data,
    );
  }

  getFolderTree(data?) {
    return new Observable((subscriber) => {
      const isUseCatch = !data || data.is_all; // only use catch when get all department
      if (isUseCatch && FolderService.folderTreeResponse) {
        subscriber.next(
          _.cloneDeep(FolderService.folderTreeResponse),
        );
        return;
      }
      this.http.get(API_URL.folders_tree, data).subscribe(
        (response) => {
          if (isUseCatch) {
            FolderService.folderTreeResponse = response;
          }
          subscriber.next(response);
        },
        (error) => {
          subscriber.error(error);
        },
      );
    });
  }
}
