import { finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  HideLoaderAction,
  ShowLoaderAction,
} from '../loader/load.actioins';
import { cloneDeep, forEach, get } from 'lodash';
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import {
  AddAttachFile,
  AddCustomLoaLevel,
  CloneLoa,
  CreateMemo,
  ErrorNotification,
  PreviewMemo,
  RemoveCustomLoaLevel,
  ResetCustomLoa,
  ResetState,
  SaveItemMemo,
  SaveMemo,
  UpdateCustomLoaLevel,
  UpdateMemo,
  UploadFile,
} from './memo.actions';
import { LoaLevel, Memo, MemoCreationData } from './memo.model';
import { MemoDetail } from 'src/app/modules/memos/model/memo.model';
import { EMPTY, Observable } from 'rxjs';
import { featureFlag } from '../../../environments/environment';
import { DDocSigningStatus } from '../../modules/loa/shared/loa.model';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@State<MemoCreationData<any>>({
  name: 'memoCreationData',
  defaults: {
    memoCreationData: null,
    isPreview: false,
    memoPreviewData: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class MemoCreationState {
  constructor(
    private memoService: MemoService,
    private alert: AlertService,
    private translate: TranslateService,
    private zone: NgZone,
    private store: Store,
    private route: Router,
    private spinner: SpinnerService,
  ) {}

  @Selector()
  static memoCreationData(
    state: MemoCreationData<MemoDetail>,
  ): MemoCreationData<MemoDetail> {
    return state;
  }

  @Action(AddCustomLoaLevel)
  addLoaLevel(
    ctx: StateContext<Memo>,
    payload: { name: string },
  ): Observable<void> {
    const state = ctx.getState();
    const pushLevelFn = (
      underState: Partial<Memo>,
    ): Partial<Memo> => {
      const presentLevel = underState.custom_loa_group.levels;
      const defaultApprover = {
        person: null,
        is_delegate: false,
        person_name: null,
      };
      const defaultLevel = {
        level: presentLevel.length,
        name: payload.name,
        members: [defaultApprover],
        signature_required: true,
        min_approve_count: 1,
        count: 1,
        user_type: 'user',
        level_duty: { duty: 'not_specified', duty_other: null },
      };
      if (featureFlag.ddoc) {
        /** Default ddoc_enable of the new level follow last level before */
        const loaLevels = presentLevel;
        const lastLevelDdocEnable =
          loaLevels[(loaLevels.length || 1) - 1]?.ddoc_enable;
        Object.assign(defaultLevel, {
          ddoc_enable: lastLevelDdocEnable || false,
        });
        /** fill default signing status */
        const defaultSigningStatus: DDocSigningStatus = {
          ddoc_use_26: lastLevelDdocEnable || false,
          ddoc_use_28: false,
          ddoc_certified_mode: false,
          ddoc_locked_mode: false,
        };
        Object.assign(defaultApprover, defaultSigningStatus);
      }
      underState.custom_loa_group.levels.push(defaultLevel);
      return { custom_loa_group: underState.custom_loa_group };
    };
    if (state.custom_loa_group == null) {
      return ctx.dispatch(new ResetCustomLoa()).pipe(
        tap(() => {
          ctx.patchState(pushLevelFn(ctx.getState()));
        }),
      );
    }
    ctx.patchState(pushLevelFn(state));
  }

  /**  Create & Update Memo */
  @Action(SaveMemo)
  save(
    { setState }: StateContext<MemoCreationData<MemoDetail>>,
    { payload },
  ): void {
    setState({
      ...payload,
    });
  }

  @Action(SaveItemMemo)
  saveItem(
    { setState, getState }: StateContext<MemoCreationData<any>>,
    { value, name }: { value: any; name: string },
  ): void {
    const state = getState();

    setState({
      ...state,
      [name]: value,
    });
  }

  @Action(AddAttachFile)
  addFile(
    {
      setState,
      getState,
    }: StateContext<MemoCreationData<MemoDetail>>,
    { file }: { file: File },
  ): void {
    const state = getState();
    const stateClone = cloneDeep(state);
    stateClone.attachments = [...stateClone.attachments, file];
    setState({
      ...stateClone,
    });
  }

  @Action(RemoveCustomLoaLevel)
  removeCustomLoaLevel(
    ctx: StateContext<Memo>,
    action: RemoveCustomLoaLevel,
  ): void {
    const state = ctx.getState();
    const customLoa = state.custom_loa_group;
    if (state.custom_loa_group.levels?.length < 1) {
      return;
    }
    if (action.index == null) {
      customLoa.levels.pop();
    } else {
      customLoa.levels.splice(action.index, 1);
    }
    ctx.patchState({
      custom_loa_group: customLoa,
    });
  }

  @Action(ResetCustomLoa)
  resetCustomLoa(ctx: StateContext<Memo>): void {
    ctx.patchState({
      custom_loa_group: { levels: [] },
    });
  }

  @Action(ResetState)
  resetState({
    setState,
  }: StateContext<MemoCreationData<any>>): void {
    setState({
      memoCreationData: null,
      isPreview: false,
      memoPreviewData: null,
    });
  }

  @Action(CreateMemo)
  createMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { payload }: { payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.createMemo(payload).pipe(
      // ดูว่าทำไม่ถึงใช้ switchMap ที่ action 'UpdateMemo'
      // look at notes in action 'UpdateMemo' on why I use switchMap.
      switchMap((createMemoRes) =>
        this.store
          .dispatch(new UploadFile(createMemoRes.id))
          .pipe(map(() => createMemoRes)),
      ),
      tap((res) => {
        // re-attach attachment since updateMemo api don't send attachment back
        res.attachments = payload.attachments;
        this.store.dispatch(new SaveMemo(res));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
        this.spinner.hide();
      }),
    );
  }

  @Action(PreviewMemo)
  previewMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { payload }: { payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.createMemo(payload).pipe(
      // ดูว่าทำไม่ถึงใช้ switchMap ที่ action 'UpdateMemo'
      // look at notes in action 'UpdateMemo' on why I use switchMap.
      switchMap((createMemoRes) =>
        this.store
          .dispatch(new UploadFile(createMemoRes.id))
          .pipe(map(() => createMemoRes)),
      ),
      tap((res) => {
        this.store.dispatch(
          new SaveItemMemo(res.signed_document, 'signed_document'),
        );
        this.store.dispatch(new SaveItemMemo(res.id, 'id'));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
      }),
    );
  }

  @Action(UpdateCustomLoaLevel)
  updateCustomLoaLevel(
    ctx: StateContext<Memo>,
    action: {
      index: number;
      updatedLoaLevel: LoaLevel;
    },
  ): void {
    const state = ctx.getState();
    const customLoa = state.custom_loa_group;
    customLoa.levels[action.index] = action.updatedLoaLevel;
    ctx.patchState({
      custom_loa_group: customLoa,
    });
  }

  @Action(UpdateMemo)
  updateMemo(
    { getState }: StateContext<MemoCreationData<MemoDetail>>,
    { id, payload }: { id: number; payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.updateMemo(id, payload).pipe(
      // TH: Note 1: ที่เราทำ switchMap ตรงนี้เพราะว่า ต้องการดูว่า action 'UploadFile' มันสำเร็จหรือเปล่า
      //       ถ้ามันไม่สำเร็จ ก็คนที่ subscribe function นี้ก็จะได้ event error กลับไปด้วย
      //     Note 2: ที่มีการ 'pipe(map(() => updateMemoRes))' ตอนท้ายก็เพราะต้องการให้ tap ด้านล่างมัน process
      //       response ของ updateMemo ไม่ใช่ response ของ UploadFile
      //
      // EN: Note 1: I use switchMap here because I want to wait for 'UploadFile' action finished.
      //       if it is failed, the error event to raised to everyone that subscribe to this function.
      //     Note 2: I use 'pipe(map(() => updateMemoRes))' in the end because I want 'tap' below to process
      //       response of 'updateMemo', not response of 'UploadFile'
      switchMap((updateMemoRes) =>
        this.store
          .dispatch(new UploadFile(id))
          .pipe(map(() => updateMemoRes)),
      ),
      tap((res) => {
        this.store.dispatch(
          new SaveItemMemo(res.signed_document, 'signed_document'),
        );
        this.store.dispatch(new SaveItemMemo(res.id, 'id'));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
        this.spinner.hide();
      }),
    );
  }

  @Action(UploadFile)
  uploadPMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { id }: { id: number },
  ): Observable<any> {
    const payload = cloneDeep(getState());
    if (!payload.attachments || payload.attachments.length === 0) {
      return EMPTY;
    }
    const newAttachments = payload.attachments.filter(
      (file) => file.id === undefined,
    );
    if (newAttachments == null || newAttachments.length === 0) {
      return EMPTY;
    }
    const at = new FormData();
    at.append('memo', id + '');
    forEach(newAttachments, (file) => {
      const name = get(file, 'name', 'relativePath');
      file.fileEntry
        ? at.append(name, file.fileEntry)
        : at.append(name, file);
    });
    return this.memoService.uploadMemoAttachment(at).pipe(
      tap({
        next: (res: []) => {
          const attachments = payload.attachments.filter(
            (file) => file.id,
          );
          if (res.length) {
            attachments.push(...res);
          }
          this.store.dispatch(
            new SaveItemMemo(attachments, 'attachments'),
          );
        },
      }),
    );
  }

  /**  Check Error Message */
  @Action(ErrorNotification)
  errorNotification(
    { getState }: StateContext<MemoCreationData<any>>,
    { error },
  ): void {
    if (error.error && error.error.en) {
      this.alert.error(
        error.error[this.translate.currentLang].join('\n'),
      );
    } else if (error.error && error.error.non_field_errors) {
      const non_field_errors: string[] = error.error.non_field_errors;
      this.alert.error(non_field_errors.join('\n'));
    } else if (error.error) {
      const errorInfos: string[] = [];
      for (const value of Object.values(error.error || {})) {
        if (typeof value === 'string') {
          errorInfos.push(value);
        } else if (Array.isArray(value)) {
          errorInfos.push(
            ...value.filter((i) => typeof i === 'string'),
          );
        }
      }
      this.alert.error(errorInfos.join('\n'));
    } else {
      this.alert.error();
    }
  }

  @Action(CloneLoa)
  cloneLoa(
    ctx: StateContext<any>,
    { normalLoa, payload }: { normalLoa: boolean; payload?: any },
  ) {
    let state: { isNormalLoa: any; cloneLoa?: boolean } = {
      isNormalLoa: normalLoa,
    };
    const cloneState = ctx.getState();
    if (cloneState.cloneLoa !== undefined) {
      state = {
        isNormalLoa: normalLoa,
        cloneLoa: cloneState.cloneLoa,
      };
    }

    if (payload !== undefined) {
      state.cloneLoa = payload;
    }
    ctx.patchState({
      ...state,
    });
  }
}
