import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDefaultZero]',
})
export class DefaultZeroDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private ngControl: NgControl) {}
  @Input() target = 0;
  ngOnInit() {
    const control = this.ngControl.control;

    this.subscription = control.valueChanges.subscribe((v) => {
      if (v === null || v === undefined || v === '') {
        control.setValue(this.target);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
