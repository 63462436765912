import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import {
  ITreeOptions,
  TreeComponent,
} from '@circlon/angular-tree-component';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FolderService } from '../../../modules/folder/folder.service';

@Component({
  selector: 'app-folder-tree-dropdown',
  templateUrl: './folder-tree-dropdown.component.html',
  styleUrls: ['./folder-tree-dropdown.component.scss'],
})
export class FolderTreeDropdownComponent
  implements OnInit, OnChanges
{
  @ViewChild('treeRoot', { static: false })
  private tree: TreeComponent;

  @Input() multiple = true;
  @Input() model: { id: number; name: string }[] | number[] = [];

  @Input() placeholder = '';
  @Input() form: FormControl;
  @Input() defaultValue;
  @Input() disable = false;

  @Input() isAllDepartment = false;

  @Output() modelChange = new EventEmitter<
    number[] | number | string
  >();

  isShow = false;
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };

  nodes;
  items = [];
  loading = false;
  formDirective: FormGroupDirective;
  allNodesLength = 0;

  checkAllDepartment: boolean;

  constructor(
    private folderService: FolderService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.model = changes.model.currentValue;

      if (this.model.length > 0) {
        this.model.forEach((element) => {
          this.setNode(element.id ? element.id : element);
        });
      } else {
        this.items = this.model;
      }
    }
  }

  ngOnInit(): void {
    const params = {
      is_all: false,
      page_size: 1000,
    };

    this.folderService
      .getFolderTree(params)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((res) => {
        this.nodes = _.cloneDeep(res);
        this.getNodeNumber();
        this.loading = true;
        if (this.model.length) {
          this.mapNameFromModel();
        } else if (this.form && this.form.value?.length) {
          this.model = this.form.value;
          this.mapNameFromModel();
        } else if (this.defaultValue) {
          this.items = this.defaultValue;
        }
      });
  }

  getNodeNumber(): void {
    this.nodes.forEach((obj) => {
      this.allNodesLength += obj.descendants_ids.length + 1;
    });
  }

  mapNameFromModel(): void {
    const params = {
      id_list: this.model.join('|'),
      page_size: 1000,
    };
    this.folderService.getFolderList(params).subscribe((res: any) => {
      this.items = res.results;
    });
  }

  nodeChecked(node, check, children = true): void {
    this._nodeChecked(node, check, children);
    this.emitData();
  }

  emitData(): void {
    const value = this.multiple
      ? this.items.map((item) => item.id)
      : this.items.map((item) => item.id).join('');
    this.modelChange.emit(value);
    if (this.form) {
      this.form.setValue(value);
    }
  }

  _nodeChecked(node, check, children): void {
    if (check) {
      if (!this.tree.treeModel.isSelected(node)) {
        this.tree.treeModel.setSelectedNode(node, true);
        this.expandAllChain(node);
      }

      if (
        this.items.find((obj) => obj.id === node.data.id) ===
        undefined
      ) {
        this.items.push(node.data);
      }

      if (children) {
        node.children
          .filter((obj) => obj !== undefined)
          .forEach((element) => {
            this._nodeChecked(element, true, true);
          });
      }
    } else {
      this.items = this.items.filter(
        (obj) => obj.id !== node.data.id,
      );
      if (children) {
        node.children.forEach((element) => {
          this.tree.treeModel.setSelectedNode(element, false);
        });
      }
    }
  }

  onActivate(event, value): void {
    this.tree.treeModel.setExpandedNode(event.node, value);
  }

  deleteItem(index: number): void {
    this.setNode(this.items[index].id, false);
    this.items.splice(index, 1);
    const value = this.multiple
      ? this.items.map((item) => item.id)
      : this.items.map((item) => item.id).join('');
    this.modelChange.emit(value);
    if (this.form) {
      this.form.setValue(value);
    }
  }

  showDropdown(): void {
    this.isShow = !this.isShow;
    this.cd.detectChanges();
    if (this.isShow && this.tree) {
      if (typeof this.items === 'object') {
        this.items.forEach((element) => {
          this.setNode(element.id ? element.id : element);
        });
      }
    }
  }

  setNode(id: number | string, value = true): void {
    if (this.tree && this.tree.treeModel) {
      const node = this.tree.treeModel.getNodeById(id);
      this.expandAllChain(node);
      this.tree.treeModel.selectedLeafNodeIds = Object.assign(
        {},
        this.tree.treeModel.selectedLeafNodeIds,
        { [node.id]: value },
      );
    }
  }

  expandAllChain(node) {
    while (node.parent !== null) {
      this.tree.treeModel.setExpandedNode(node.parent, true);
      node = node.parent;
    }
  }

  clearValue(): void {
    this.items = [];
    this.modelChange.emit('');
    if (this.form && this.form.value) {
      this.form.setValue('');
    }
  }
}
