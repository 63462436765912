<ng-template #deleteFolderConfirmationModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header red">
    <h4 class="modal-title">
      {{"FOLDER.PERMANENTLY_DELETE_FOLDER"|translate}}
    </h4>
  </div>
  <div class="modal-body text-left">
    <div class="text-center mb-1">
      <svg-icon
        [svgStyle]="{ 'width.px': 35, 'height': 'max-height' }"
        class="size-svg mr-2 align-middle"
        src="assets/svg/warning.svg"></svg-icon>
      <span class="warning align-middle">{{"FOLDER.WARNING"|translate}}!</span>
    </div>
    <div class="mt-3 mb-3">
      <span class="f-14">{{"FOLDER.DELETE_FOLDER_CAUTION_DETAIL1"|translate}} </span>
      <span class="f-14 font-weight-bold">
        {{ data.name }}
      </span>
      <span class="f-14"> {{ "FOLDER.DELETE_FOLDER_CAUTION_DETAIL2"|translate }}</span>
    </div>

    <div>
      <input type="checkbox"
           class="check pointer mr-2 mb-1 align-middle"
           [ngModel]="true"
           [disabled]="true">
      <span class="f-14 align-middle">{{"FOLDER.DELETE_FOLDER"|translate}}</span>
    </div>
    <div>
      <input type="checkbox"
           class="check pointer mr-1 mb-1 align-middle"
           [ngModel]="deleteMemo"
           (click)="checkDeleteMemo()">
      <span class="f-14 align-middle pointer"
          (click)="checkDeleteMemo()">
          {{"FOLDER.DELETE_ALL_MEMO"|translate}}</span>
    </div>
    <span *ngIf="deleteMemo" class="warning-memo ml-2">
      * {{"FOLDER.WARNING_DELETE_MEMO"|translate}}
    </span>
    <div class="mt-2">
      <span class="f-14">{{"FOLDER.IRREVERSIBLE"|translate}}</span>
      <br>
      <span class="f-14">{{"FOLDER.CANNOT_RECOVER"|translate}}</span>
    </div>


  </div>
  <div class="modal-footer">
    <button class="btn btn-outline"
        [style.--c]="themeList?.header_table_color"
        (click)="c()">
      {{'MEMOS.CANCEL'|translate}}
    </button>
    <button class="btn"
            type="button"
            appSetTheme
            (click)="submit()">
      {{'FOLDER.PERMANENTLY_DELETE'|translate}}
    </button>
  </div>
</ng-template>

<svg-icon
    class="size-svg pointer"
    src="assets/svg/delete.svg"
    (click)="open(deleteFolderConfirmationModal)"></svg-icon>
