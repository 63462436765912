<div class="d-flex"
    id="wrapper"
    style="max-height: 100vh; max-height: 100dvh;"
    *ngIf="navbarActive">
  <div class="position-relative">
    <div class="position-absolute position-logo">
      <div class="mx-2"
          [ngStyle]="{'color': themeList?.text_color}">
        <i class="pointer custom-hamburger fa fa-bars mt-3 hamburger-pc"
            aria-hidden="true"
            (click)="isFullSidebar = !isFullSidebar;"></i>
        <i class="pointer custom-hamburger fa fa-bars mt-3 hamburger-mobile"
        *ngIf="!openSidebar"
            aria-hidden="true"
            (click)="openSidebar = !openSidebar; isFullSidebar = true;"></i>
      </div>

      <div class="flex-grow-1 ml-4 logo-none-mobile">
        <img [src]="themeList?.display_sidebar? (themeList.display_sidebar | secure | async): 'assets/images/codium_logo.png' "
            style="max-height: 50px;"
            class="pl-3">
      </div>
    </div>

  </div>
  <!-- Sidebar -->
  <div class="width-sidebar"
      id="sidebar-wrapper"
      [ngClass]="{'position-sidebar':openSidebar,'hidden-sidebar':!openSidebar,
      'width-sidebar-full': !isFullSidebar}"
      [ngStyle]="{
        background:
          'linear-gradient(to top, ' +
          themeList?.bg_color_primary +
          ', ' +
          themeList?.bg_color_secondary +
          ' 60%)',
        color: themeList?.text_color
      }"
      (window:resize)="onResize($event)">
    <div class="sidebar-heading d-flex">
      <div class="pointer mx-2 position-relative"
          [ngClass]="openSidebar? 'd-none':''"
          [ngStyle]="{'color': themeList?.text_color, 'min-width': isFullSidebar? '0':'50px'}">
        <!-- <i class="custom-hamburger position-burger fa fa-bars"
            [ngStyle]="{'right': isFullSidebar? '-20px':'10px'}"
            aria-hidden="true"
            (click)="openFullSidebar()"></i> -->
      </div>
      <div class="flex-grow-1"
          [ngClass]="!isFullSidebar? 'ml-0':'ml-3'"
          style="height: 40px;">
        <img [src]="themeList?.display_sidebar? (themeList.display_sidebar | secure | async): 'assets/images/codium_logo.png' "
            style="max-height: 50px;"
            class="show-logo-mobile">
      </div>
      <span class="show-close pointer pr-4 text-right"
          (click)="openSidebar = !openSidebar; isFullSidebar = false;"><i class="fas fa-times"></i></span>
    </div>

    <!-- /#sidebar-wrapper -->
    <div class="list-group list-group-flush pt-4"
        [ngClass]="isFullSidebar ? 'text-left':'text-center'">

      <div class="menu-main overflow-auto"
          [style.--padding]="(isFullSidebar? '1.5':'0.5') + 'rem'">
        <!-- News Feed -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'news-feed']"
            class="mt-2"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            ngbTooltip="{{'SIDEBAR.NEWS-FEED' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <svg-icon src="assets/svg/icon-menu/blog.svg"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.NEWS-FEED'|translate}}
            </span>
          </a>
        </div>

        <!-- Approval -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'pending-approval']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['General']"
            ngbTooltip="{{'SIDEBAR.PENDING' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action notification px-menu-4">
            <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.PENDING'|translate}}
            </span>
            <span class="badge-number d-inline-block"
                *ngIf="notificationService.memoNotificationCount > 0">
              {{ notificationService.memoNotificationCount > 99 ? '99+' : notificationService.memoNotificationCount }}
            </span>
          </a>
        </div>

        <!-- Management -->
        <div class="custom-border custom-border-top"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [ngStyle]="{'background': departmentSubMenu? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              #rla="routerLinkActive"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              ngbTooltip="{{'SIDEBAR.MANAGEMENT'|translate}}"
              (click)="departmentSubMenu=!departmentSubMenu">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative ">
              <svg-icon src="assets/svg/icon-menu/org.svg"
                  class="mt-title-svg"
                  [stretch]="true"
                  [svgStyle]="{
                    'fill': rla?.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    'height.px': 20,
                    'width.px': 20
                  }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">{{'SIDEBAR.MANAGEMENT'|translate}}
              </span>
              <div class="text-right pl-2 position-absolute position-arrow"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                    [ngClass]=" departmentSubMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- Department level -->
          <ng-container *ngIf="departmentSubMenu">
            <div routerLinkActive="active"
                [routerLink]="['/', 'department', 'level']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">

                <svg-icon src="assets/svg/icon-menu/department-level.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}
                </span>
              </a>
            </div>

            <!-- Department -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'department', 'add']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.DEPARTMENT' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <!-- Department -->
                <svg-icon src="assets/svg/icon-menu/events.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ?
                        themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DEPARTMENT' | translate}}
                </span>
              </a>
            </div>

            <!-- Folder -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'folder', 'add']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.FOLDER' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <!-- Folder -->
                <svg-icon src="assets/svg/icon-menu/folders.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ?
                        themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.FOLDER' | translate}}
                </span>
              </a>
            </div>

            <!-- Manage Users -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'users']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.MANAGE-USERS' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <!-- Manage Users -->
                <svg-icon src="assets/svg/icon-menu/user--profile.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-USERS'|translate}}
                </span>
              </a>
            </div>

            <!-- LOA -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'loa']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.MANAGE-APPROVAL' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/category.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-APPROVAL'|translate}}
                </span>
              </a>
            </div>

            <!-- CC Group -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'cc-group']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.MANAGE-CC-GROUP' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/cc.svg"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-CC-GROUP'|translate}}
                </span>
              </a>
            </div>

            <!-- All Document (Trash) -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'memos', 'trash']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.ALL-DOCUMENTS' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.ALL-DOCUMENTS'|translate}}
                </span>
              </a>
            </div>
            <!-- upload memo type -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'manage-types-upload-memo', 'type']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.UPLOAD-MEMO-TYPE' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/catalog.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ?
                      themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                        color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.UPLOAD-MEMO-TYPE' | translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Manage MasterData -->
        <div class="custom-border custom-border-top"
             *appCheckPermission="['Admin', 'Master Admin']"
             [ngStyle]="{'background': manageMasterData? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
               #rla="routerLinkActive"
               class="mt-2 hasSubMenu"
               *appCheckPermission="['Admin', 'Master Admin', 'IT']"
               [style.--color]="themeList?.hovered_text_color"
               [style.--bg]="themeList?.hovered_text_color + '20'"
               placement="right"
               ngbTooltip="{{'MASTER-DATA.MANAGE-MASTER-DATA'|translate}}"
               (click)="manageMasterData=!manageMasterData">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative">
              <svg-icon src="assets/svg/icon-menu/database.svg"
                        class="mt-title-svg"
                        [stretch]="true"
                        [svgStyle]="{
                'fill': rla?.isActive
                  ? themeList?.hovered_text_color
                  : themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{'color': themeList?.text_color}">{{"MASTER-DATA.MANAGE-MASTER-DATA"|translate}}</span>
              <div class="text-right pl-2 position-absolute position-arrow"
                   [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                   [ngClass]=" manageMasterData ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- Master Data Company Branch -->
          <ng-container *ngIf="manageMasterData">
            <div routerLinkActive="active"
                 [routerLink]="['/', 'company']"
                 #rla="routerLinkActive"
                 [style.--color]="themeList?.hovered_text_color"
                 [style.--bg]="themeList?.hovered_text_color + '20'"
                 *appCheckPermission="['Admin', 'Master Admin']"
                 ngbTooltip="{{'MASTER-DATA.COMPANY-LIST' | translate}}"
                 placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/location-company.svg"
                          [stretch]="true"
                          [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'MASTER-DATA.COMPANY-LIST' | translate}}
                </span>
              </a>
            </div>
          </ng-container>

          <!-- Master Data Supplier -->
          <ng-container *ngIf="manageMasterData">
            <div routerLinkActive="active"
                 [routerLink]="['/', 'supplier']"
                 #rla="routerLinkActive"
                 [style.--color]="themeList?.hovered_text_color"
                 [style.--bg]="themeList?.hovered_text_color + '20'"
                 *appCheckPermission="['Admin', 'Master Admin']"
                 ngbTooltip="{{'MASTER-DATA.Supplier List' | translate}}"
                 placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/supplier.svg"
                          [stretch]="true"
                          [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'MASTER-DATA.Supplier List' | translate}}
                </span>
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="manageMasterData">
            <div routerLinkActive="active"
                 [routerLink]="['/', 'attachment-manage']"
                 #rla="routerLinkActive"
                 [style.--color]="themeList?.hovered_text_color"
                 [style.--bg]="themeList?.hovered_text_color + '20'"
                 *appCheckPermission="['Admin', 'Master Admin']"
                 ngbTooltip="{{'att.Attachments' | translate}}"
                 placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/catalog.svg"
                  [stretch]="true"
                  [svgStyle]="{
                    'fill': rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    'height.px': 20,
                    'width.px': 20
                  }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'att.Attachments' | translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Certificate -->
        <ng-container *appFeatureFlag="'ddoc'">
          <div class="custom-border"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [ngStyle]="{'background': manageCertificate? '': '' , 'color': themeList?.text_color}">
            <!-- manage certificate -->
            <div routerLinkActive="active"
                class="mt-2 hasSubMenu"
                #rla="routerLinkActive"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                placement="right"
                ngbTooltip="{{'CSR.CERTIFICATE'|translate}}"
                (click)="manageCertificate=!manageCertificate">
              <a class="list-group-item list-group-item-action px-menu-4 position-relative">
                <svg-icon src="assets/svg/icon-menu/document.svg"
                    class="mt-title-svg"
                    [stretch]="true"
                    [svgStyle]="{
                'fill': rla?.isActive
                  ? themeList?.hovered_text_color
                  : themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
                </svg-icon>
                <span class="w-100 f-size-17 pt-1 pl-2"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{'color': themeList?.text_color}">{{'CSR.CERTIFICATE'|translate}}</span>
                <div class="text-right pl-2 position-absolute position-arrow "
                    [ngStyle]="{'color': themeList?.text_color}">
                  <i class="fal"
                      [ngClass]=" manageCertificate ? 'fa-angle-up' : 'fa-angle-down'"></i>
                </div>
              </a>
            </div>

            <!-- manage csr -->
            <ng-container *ngIf="manageCertificate">
              <ng-container *appFeatureFlag="'ddoc_enable_feature_28'">
                <div routerLinkActive="active"
                    [routerLink]="['/', 'manage-csr']"
                    #rla="routerLinkActive"
                    [style.--color]="themeList?.hovered_text_color"
                    [style.--bg]="themeList?.hovered_text_color + '20'"
                    *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                    ngbTooltip="{{'CSR.CSR-LIST' | translate}}"
                    placement="right">
                  <a class="list-group-item list-group-item-action px-menu-4">
                    <svg-icon src="assets/svg/icon-menu/carbon_request-quote.svg"
                        [stretch]="true"
                        [svgStyle]="{ 'fill': (rla.isActive ?
                    themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                    </svg-icon>
                    <span class="pl-2 f-size-17"
                        *ngIf="isFullSidebar"
                        [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
                      {{'CSR.CSR-LIST' | translate}}
                    </span>
                  </a>
                </div>
              </ng-container>
            </ng-container>

            <!-- Manage Certificate -->
            <ng-container *ngIf="manageCertificate">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'certificate']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                  ngbTooltip="{{'SIDEBAR.CERTIFICATE' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-menu-4">
                  <!-- Manage Certificate -->
                  <svg-icon src="assets/svg/icon-menu/certificate.svg"
                      [stretch]="true"
                      [svgStyle]="{
              'fill': rla.isActive
                ? themeList?.hovered_text_color
                : themeList?.text_color,
                'stroke': rla.isActive
                ? themeList?.hovered_text_color
                : themeList?.text_color,
              'height.px': 20,
              'width.px': 20
            }">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
              color: rla.isActive
                ? themeList?.hovered_text_color
                : themeList?.text_color
              }">
                    {{'SIDEBAR.CERTIFICATE'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Usage Information -->
        <div class="custom-border"
            *appCheckPermission="['Admin', 'Master Admin']"
            [ngStyle]="{'background': usageInformation? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              #rla="routerLinkActive"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              ngbTooltip="{{'SIDEBAR.USAGE-INFORMATION'|translate}}"
              (click)="usageInformation=!usageInformation">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative">
              <svg-icon src="assets/svg/icon-menu/carbon_information.svg"
                  class="mt-title-svg"
                  [stretch]="true"
                  [svgStyle]="{
                'fill': rla?.isActive
                  ? themeList?.hovered_text_color
                  : themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">{{"SIDEBAR.USAGE-INFORMATION"|translate}}</span>
              <div class="text-right pl-2 position-absolute position-arrow"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                    [ngClass]=" usageInformation ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- usage information -->
          <ng-container *ngIf="usageInformation">
            <!-- Dashboard -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'kpi-dashboard']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.DASHBOARD' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/dashboard.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DASHBOARD' | translate}}
                </span>
              </a>
            </div>

            <!-- Usage Dashboard -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'usage-dashboard', 'usage']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                [ngbTooltip]="'SIDEBAR.Package-Usage'|translate"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/credit-usage.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ? themeList?.hovered_text_color: themeList?.text_color),
        'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                  {{'SIDEBAR.Package-Usage'|translate}}
                </span>
              </a>
            </div>

            <!-- Memo Report -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'memo-report']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.MEMO-REPORT' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/text-link--analysis.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MEMO-REPORT' | translate}}
                </span>
              </a>
            </div>

            <!-- Operation Log -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'operation-log']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.OPERATION-LOG' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/task--view.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.OPERATION-LOG' | translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Memo -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'memos']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['General']"
            ngbTooltip="{{'SIDEBAR.MEMOS' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <!-- Manage Users -->
            <svg-icon src="assets/svg/icon-menu/memo.svg"
                [stretch]="true"
                [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.MEMOS' | translate}}
            </span>
          </a>
        </div>

        <!-- Folder-File List -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'folder', 'files']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['General']"
            ngbTooltip="{{'SIDEBAR.FOLDERS' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <svg-icon src="assets/svg/icon-menu/folder.svg"
                [stretch]="true"
                [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.FOLDERS' | translate}}
            </span>
          </a>
        </div>

        <!-- File Pending Approval -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'folder', 'pending']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *ngIf="profile.can_approve_duplicate || profile.can_approve_uploaded_file"
            ngbTooltip="{{'SIDEBAR.FOLDER-PENDING' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action notification px-menu-4">
            <svg-icon src="assets/svg/icon-menu/folder-add.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.FOLDER-PENDING'|translate}}
            </span>
            <span class="badge-number d-inline-block"
                *ngIf="notificationService.fileTaskNotificationCount > 0">
              {{ notificationService.fileTaskNotificationCount > 99 ? '99+' : notificationService.fileTaskNotificationCount }}
            </span>
          </a>
        </div>

        <!-- Config -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'configuration']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['Admin', 'Master Admin']"
            ngbTooltip="{{'SIDEBAR.CONFIGURATION' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <svg-icon src="assets/svg/icon-menu/setting-theme.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.CONFIGURATION' | translate}}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Content -->
  <div id="page-content-wrapper"
      class="content-height">
    <app-navbar [profile]="profile"></app-navbar>

    <ng-container *appFeatureFlag="'ddoc'">
      <div class="col-12 px-0"
          *ngIf="showWarning &&
            profile?.otp_type === null &&
            (checkRouteActive('/pending-approval') ||
              checkRouteActive('/news-feed') ||
              checkRouteActive('/memos'))">
        <div class="box-warning col-12 d-flex">
          <i class="fal fa-exclamation-triangle align-self-center mr-2"
              style="color:rgb(216, 187, 24)"></i>
          <span class="align-self-center">{{ 'CERTIFICATE.OTP-NOT-ENABLE'|translate }}</span>
          <button class="btn align-self-center ml-3"
              [routerLink]="['/', 'profile-detail', 'otp']"
              appSetTheme
              type="button">
            <span style="font-size: 13px">{{'CERTIFICATE.GOTO-OTP-SETTINGS'|translate}}</span>
          </button>
          <a class="text-black p-2 align-self-center ml-auto"
              href="javascript:void(0)"
              role="button"
              (click)="showWarning = false">
            <i class="fal fa-times"></i>
          </a>
        </div>
      </div>
    </ng-container>

    <div class="container-fluid overflow-auto p-0">
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
